/** @format */

import OverviewPageHero from './OverviewPageHero';
import AssignmentCard from './AssignmentCard';

const OverviewPage = (props) => {
	console.log(props.data);
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};
	let itemCount = -1;

	const countItemTypes = () => {
        let count = {reading:0, video:0,activity:0,question:0, other:0, total: 0, totalPoints:0, totalTime:0}

        props.data.assignmentParts.forEach(part => {
            part.assignmentItems.forEach(item => {
                count.total = count.total + 1
                count.totalPoints = count.totalPoints + item.totalPoints
                count.totalTime = count.totalTime + +item.time //in seconds
                if (item.type === 'reading') {
                    count.reading = count.reading + 1
                    
                } else if (item.type === 'video') {
                    count.video = count.video + 1
                } else if (item.type === 'activity') {
                    count.activity = count.activity + 1
                } else if (item.type === 'question') {
                    count.question = count.question + 1
                } else if (item.type === 'video') {
                    count.video = count.video + 1
                } else {
                    count.other = count.other + 1
                }
            })
        })

        return count
    }

	return (
		<main className='overview-main'>
			<OverviewPageHero
				assighmentType={props.data.type[0].desktop}
				assignmentName={props.data.name}
				dueDate={props.data.duedate}
				dueTime={props.data.duetime}
				overallScore={props.data.overallscore}
				totalPoints={countItemTypes().totalPoints}
                totalTime={props.data.totalTime}
                itemCount={countItemTypes()}
                totalItems={countItemTypes().total}
				itemsCompleted={props.data.itemsCompleted}
				summary={props.data.summary}
				openPlayer={props.openPlayer}
				inView={props.inView}
			/>

			<section className='overview-body'>
				<div className='wrapper'>
					<div className='overview-body__left'>
						{props.data.assignmentParts.map((part, i) => {
							return (
								<ol
									className='assignment-group'
									key={`part${i}`}
								>
									{part.assignmentItems.map((item, j) => {
										itemCount = itemCount + 1;
										return (
											<li key={`item${j}`}>
												<AssignmentCard
													data={item}
													gotoItem={props.gotoItem}
													itemCount={itemCount}
													className='in-overview'
												/>
											</li>
										);
									})}
								</ol>
							);
						})}
					</div>
					<div className='overview-body__right'>
						<h4>This assignment contains:</h4>
						<ul className='assignment-summary'>
							{countItemTypes().reading > 0 && 
                                    <li className={"readings"}>
                                            {countItemTypes().reading} {countItemTypes().reading > 1 ? "Readings" : "Reading"}
                                    </li> }
                                    {countItemTypes().video > 0 && 
                                    <li className={"videos"}>
                                            {countItemTypes().video} {countItemTypes().video > 1 ? "Videos" : "Video"}
                                    </li> }
                                    {countItemTypes().activity > 0 && 
                                    <li className={"activities"}>
                                            {countItemTypes().activity} {countItemTypes().activity > 1 ? "Activities" : "Activity"}
                                    </li> }
                                    {countItemTypes().question > 0 && 
                                    <li className={"questions"}>
                                            {countItemTypes().question} {countItemTypes().question > 1 ? "Questions" : "Question"}
                                    </li> }
						</ul>

						<div className='assignment-objectives'>
							<h4>What you will learn</h4>
							<ul>
								{props.data.learnings.map((item, i) => {
									return <li key={`learning${i}`}>{item.item}</li>;
								})}
							</ul>
						</div>
					</div>
				</div>
			</section>

			{/* <button
				className='back-to-top'
				onClick={scrollToTop}
			>
				<svg
					width='12'
					height='7'
					viewBox='0 0 12 7'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M6.62194 0.238387L6.62191 0.238431C6.63548 0.250546 6.64897 0.26335 6.66235 0.276859L11.7256 5.38635C12.0915 5.75549 12.0915 6.354 11.7256 6.72314C11.3598 7.09229 10.7667 7.09229 10.4009 6.72314L6 2.28205L1.59906 6.72314C1.23325 7.09229 0.640163 7.09229 0.274356 6.72314C-0.0914519 6.354 -0.0914519 5.75549 0.274356 5.38635L5.33765 0.276859C5.69027 -0.0789827 6.25409 -0.0918069 6.62194 0.238387Z'
						fill='#FEFEFE'
					/>
				</svg>
			</button> */}
		</main>
	);
};

export default OverviewPage;
