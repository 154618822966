/** @format */

import React, { useState, useEffect, useRef } from 'react';
import ChatbotDrawer from './ChatbotDrawer';
import ChatbotRail from './ChatbotRail';

const ChatbotContainer = (props) => {
	const [chatHistory, setChatHistory] = useState([]);
	const updateChatHistory = (userEntry) => {
		setChatHistory([...chatHistory, userEntry]);
	};

	return (
		<div className='chatbot'>
			{props.drawerOpen && (
				<ChatbotDrawer
					close={() => props.changeDrawerOpen(false)}
					drawerMode={props.drawerMode}
					changeDrawerMode={props.changeDrawerMode}
					pageType={props.pageType}
					item={props.item}
					drawerType={props.drawerType}
					chatHistory={chatHistory}
					updateChatHistory={updateChatHistory}
				/>
			)}

			<ChatbotRail
				drawerOpen={props.drawerOpen}
				changeDrawerType={props.changeDrawerType}
				openDrawer={(foo) => {
					props.changeDrawerOpen(true);
				}}
			/>
		</div>
	);
};
export default ChatbotContainer;
