/** @format */

import Skeleton from './Skeleton';

const SearchLoading = () => {
	return (
		<div className='search-loading'>
			<div className='search-glossary'>
				<header>
					<h3>Glossary</h3>
				</header>
				<Skeleton variant='text' />
				<Skeleton variant='text' />
				<Skeleton variant='text' />
			</div>
			<div className='search-etextbook'>
				<header>
					<h3>Etextbook</h3>
					<a href='!#'>Show all (10)</a>
				</header>
				<div className='content'>
					<Skeleton
						variant='rectangle'
						width='100'
						height='150'
					/>
					<ul>
						<li>
							<Skeleton
								variant='text'
								width='90%'
							/>
							<Skeleton
								variant='text'
								width='80%'
							/>
						</li>
						<li>
							<Skeleton
								variant='text'
								width='90%'
							/>
							<Skeleton
								variant='text'
								width='80%'
							/>
						</li>
						<li>
							<Skeleton
								variant='text'
								width='90%'
							/>
							<Skeleton
								variant='text'
								width='80%'
							/>
						</li>
					</ul>
				</div>
			</div>
			<div className='search-media'>
				<header>
					<h3>Images</h3>
				</header>
				<div className='content'>
					<ul className='media'>
						<li>
							<Skeleton
								variant='rectangle'
								width='148'
								height='90'
							/>

							<Skeleton
								variant='text'
								width='90%'
							/>
						</li>
						<li>
							<Skeleton
								variant='rectangle'
								width='148'
								height='90'
							/>

							<Skeleton
								variant='text'
								width='90%'
							/>
						</li>
						<li>
							<Skeleton
								variant='rectangle'
								width='148'
								height='90'
							/>

							<Skeleton
								variant='text'
								width='90%'
							/>
						</li>
					</ul>
				</div>
			</div>
			<div className='search-media'>
				<header>
					<h3>Videos</h3>
				</header>
				<div className='content'>
					<ul className='media'>
						<li className='card'>
							<Skeleton
								variant='rectangle'
								width='200'
								height='96'
							/>

							<Skeleton
								variant='text'
								width='90%'
							/>
						</li>
						<li className='card'>
							<Skeleton
								variant='rectangle'
								width='200'
								height='96'
							/>

							<Skeleton
								variant='text'
								width='90%'
							/>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};
export default SearchLoading;
