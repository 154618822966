/** @format */

import PlayerNavigation from './PlayerNavigation';
import PlayerContent from './PlayerContent';
import { useEffect, useRef, useState } from 'react';
import PlayerHeader from './PlayerHeader';

const Player = (props) => {
	const [answerSelected, setAnswerSelected] = useState(false);
	const [answerCorrect, setAnswerCorrect] = useState(false);
	const [navigationOpen, setNavigationOpen] = useState(!props.drawerOpen); //Navigation is closed if chat drawer is open
	const [mobileMenuOpen, setmobileMenuOpen] = useState(false);
	const [selectedAnswer, setSelectedAnswer] = useState(false);
	const [modbilescoreOpen, setMobileScoreOpen] = useState(false);

	let scorePercentage = parseInt(
		(props.data.overallscore / props.data.totalPoints) * 100
	);

	const selectedCardRef = useRef(null);
	const fbRef = useRef(null);

	const selectAsnwer = (selAns) => {
		setSelectedAnswer(selAns);
		setAnswerSelected(true);
	};

	const answerEntered = () => {
		setAnswerSelected(true);
	};

	const checkAnswer = () => {
		setAnswerCorrect(true);
		setTimeout(() => {
			fbRef.current.scrollIntoView(true);
		}, 200);
	};

	const toggleNavigation = () => {
		setNavigationOpen(!navigationOpen);
	};

	const nextItem = () => {
		setAnswerSelected(false);
		setAnswerCorrect(false);
		setSelectedAnswer(false);
		props.nextItem();
	};
	const prevItem = () => {
		
		props.prevItem(props.currentItem - 1);
	};
	const toggleMobileMenu = () => {
		setmobileMenuOpen(!mobileMenuOpen);
	};
	const toggleMobileScores = () => {
		setMobileScoreOpen(!modbilescoreOpen);
	};

	useEffect(() => {
		setTimeout(() => {
			if (selectedCardRef.current) {
				document.getElementById('card-selected').scrollIntoView(true);
			}
		}, 300);
		localStorage.setItem('currentItem', props.currentItem);
	});

	//Close navigation when chat drawer is open.
	useEffect(() => {
		if (props.drawerOpen) {
			setNavigationOpen(false);
		}
	}, [props.drawerOpen]);

	return (
		<>
			<PlayerHeader toggleMobileMenu={toggleMobileMenu} mobileMenuOpen={mobileMenuOpen} 
				show={true}
				data={props.data} closePlayer={props.closePlayer}/>
			<main className='player-main'>
				<PlayerNavigation
					data={props.data}
					currentItem={props.currentItem}
					gotoItem={props.gotoItem}
					itemsCompleted={props.itemsCompleted}
					totalItems={props.totalItems}
					toggleNavigation={toggleNavigation}
					navigationOpen={navigationOpen}
					mobileMenuOpen={mobileMenuOpen}
					toggleMobileMenu={toggleMobileMenu}
					ref={selectedCardRef}
				/>

				<PlayerContent
					data={props.itemsList[props.currentItem]}
					currentItem={props.currentItem}
					nextItem={nextItem}
					prevItem={prevItem}
					answerSelected={answerSelected}
					selectAsnwer={selectAsnwer}
					answerCorrect={answerCorrect}
					checkAnswer={checkAnswer}
					selectedAnswer={selectedAnswer}
					navigationOpen={navigationOpen}
					answerEntered={answerEntered}
					currentStep={props.currentStep}
					totalSteps={props.totalSteps}
					ref={fbRef}
				/>
			</main>
		</>
	);
};

export default Player;
