/** @format */

import React, { useState } from 'react';
import LightModeIcon from '../assets/images/icon_light_mode.svg';
import DarkModeIcon from '../assets/images/icon_dark_mode.svg';
import PinIcon from '../assets/images/icon_pin.svg';

const ModeToggle = () => {
	const [lightMode, setLightMode] = useState(true);
	return (
		<div>
			{/* <button className='icon-btn'>
				<img
					src={PinIcon}
					alt=''
				/>
			</button> */}
			<button
				className='mode-toggle'
				aria-pressed={!lightMode}
				aria-label='Use Dark mode'
				onClick={() => setLightMode(!lightMode)}
			>
				<span className='dark'>
					<img
						src={DarkModeIcon}
						alt=''
					/>
				</span>
				<span className='light'>
					<img
						src={LightModeIcon}
						alt=''
					/>
				</span>
			</button>
		</div>
	);
};

export default ModeToggle;
