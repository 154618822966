/** @format */

const WelcomeMessage = () => {
	return (
		<div className='overview-welcome'>
			<p>Welcome!</p>
			<p>
				In this assignment, you'll explore the microscopic structure and
				function of skeletal muscle fibers.
			</p>
			<h4>Key Tasks:</h4>

			<ul>
				<li>
					<strong>Read:</strong> Carefully review the provided materials on
					skeletal muscle fibers, focusing on the structure of a sarcomere.
				</li>
				<li>
					<strong>Watch:</strong> Watch the assigned video to gain a visual
					understanding of the sarcomere's anatomy.
				</li>
				<li>
					<strong>Apply:</strong> Use your newfound knowledge to complete four
					practice questions and activities.
				</li>
			</ul>

			<p>
				<strong>Remember:</strong> The goal of this assignment is to solidify
				your understanding of skeletal muscle fibers, particularly the
				sarcomere. Good luck!
			</p>

			<p>
				<strong>Tip:</strong> Select Coach Me whenever you want guidance on how
				to improve your performance and study skills.{' '}
			</p>
		</div>
	);
};

export default WelcomeMessage;
