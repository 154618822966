/** @format */

import React, { useEffect, useState, useRef } from 'react';
import historyIcon from '../assets/images/icon_chat_history.svg';
import SearchIcon from '../assets/images/Icon_chat_search.svg';
import botIcon from '../assets/images/icon_ai_chatbot.svg';

const ChatbotTabs = (props) => {
	const [selected, setSelected] = useState('');
	const tabList = useRef();
	const slider = useRef();

	const selectTab = (drawer) => {
		setSelected(drawer);
		props.openDrawer(drawer); //for when this is connected
		props.changeDrawerType(drawer);
	};

	const positionSlider = () => {
		let tabListBox = tabList.current.getBoundingClientRect(),
			tabListBoxTop = tabListBox.top;

		// might not need to change height if icon buttons are same size

		if (tabList.current.querySelector('.drawer-btn[aria-selected=true]')) {
			let activeTabBox = tabList.current
					.querySelector('.drawer-btn[aria-selected=true]')
					.getBoundingClientRect(),
				tabTop = activeTabBox.top,
				tabHeight = activeTabBox.height;

			slider.current.style.top = tabTop - tabListBoxTop + 'px';
			slider.current.style.height = tabHeight + 'px';
		}
	};

	useEffect(() => {
		if (props.drawerOpen) {
			setTimeout(() => positionSlider(), 60);
		}
	}, [selected, props.drawerOpen]);
	return (
		<div
			className='chatbot-actions'
			ref={tabList}
		>
			<button
				className='tab-action drawer-btn'
				role='tab'
				onClick={() => selectTab('chat')}
				aria-selected={selected === 'chat'}
			>
				<img
					src={botIcon}
					alt=''
				/>
			</button>
			<button
				className='tab-action drawer-btn'
				role='tab'
				onClick={() => selectTab('history')}
				aria-selected={selected === 'history'}
			>
				<img
					src={historyIcon}
					alt=''
				/>
			</button>
			<button
				className='tab-action drawer-btn'
				role='tab'
				onClick={() => selectTab('search')}
				aria-selected={selected === 'search'}
			>
				<img
					src={SearchIcon}
					alt=''
				/>
			</button>

			{/* do we need sliding rail */}
			{props.drawerOpen && (
				<div
					className='tab-slider'
					ref={slider}
				></div>
			)}
		</div>
	);
};
export default ChatbotTabs;
