/** @format */

import React, { useEffect, useRef, useState } from 'react';
import OpenAI from 'openai';
import iconClose from '../assets/images/icon_close_gradient.png';
import DrawerViewMenu from './DrawerViewMenu';
import prompts from '../assets/data/chat/prompts.json';
import SearchContent from './SearchContent';
import WelcomeMessage from './WelcomeMessage';

// Initialize the OpenAI client
const openai = new OpenAI({
	apiKey: process.env.REACT_APP_OPENAI_API_KEY,
	dangerouslyAllowBrowser: true,
});

const ChatbotDrawer = (props) => {
	const [assistant, setAssistant] = useState(
		'asst_7xx1JDdodYcm1rNqZigqk6K9'
	); /* ID from the OpenAI Assistant for summary. 'asst_k1fJy9haum4RUIEJkXhkZeho' is the key for the explain assistant */
	const [enter, setEnter] = useState(true);
	const [coachMeCount, setCoachMeCount] = useState(0);
	const [thread, setThread] = useState(null);
	const [output, setOutput] = useState('');
	const [isStreaming, setIsStreaming] = useState(false);
	const [welcomePrompt, setWelcomePrompt] = useState(null);

	const [textInput, setTextInput] = useState('');

	//const [openAiPrompts, setOpenAiPrompts] = useState([]);

	const input = useRef();
	const messagesEndRef = useRef();

	/* Submit chat input form */
	const submitChatInput = (e) => {
		e.preventDefault();

		sentChat(textInput.toLowerCase());

		if (textInput !== 'yes' && textInput !== 'no') {
			if (
				textInput.indexOf('explain') > -1 ||
				textInput.indexOf('define') > -1
			) {
				setAssistant('asst_k1fJy9haum4RUIEJkXhkZeho');
				interactWithSumamrizeAssistant(assistant, textInput);
			} else {
				if (textInput.indexOf('Summarize this') > -1) {
					let userInput = `Summarize section about ${props.item.name}`;
					interactWithSumamrizeAssistant(assistant, userInput);
				} else {
					interactWithSumamrizeAssistant(assistant, textInput);
				}
			}
		}
	};

	/* Get the openAi prompts for specific section
	useEffect(() => {
		let currentID = props.item.itemid,
			currentPrompts = prompts.filter((f) => f.screenID === currentID);

		console.log(currentPrompts);

		if (
			currentPrompts.length > 0 &&
			currentPrompts[0].openAIPrompts.length > 0
		) {
			setOpenAiPrompts(currentPrompts[0].openAIPrompts);
		}
	}, [props.item]); */

	useEffect(() => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [props.chatHistory]);

	/* Interact with the summarize assistant in OpenAI */
	const interactWithSumamrizeAssistant = async (assistantId, userMessage) => {
		// addMessagetoHistory('user', userMessage);
		//console.log('user message', openAiPrompts);

		//Create a thread
		const thread = await openai.beta.threads.create();
		console.log('Thread created with id:', thread.id);

		setThread(thread);

		console.log('Creating message...');
		await openai.beta.threads.messages.create(thread.id, {
			role: 'user',
			content: userMessage,
		});
		console.log('Message created');
		let userEntry = { user: 'user', text: userMessage };
		let newEntry = { user: 'Assistant', text: '' };

		let copiedHistory = [...props.chatHistory, userEntry, newEntry];
		console.log(props.chatHistory);
		console.log(copiedHistory);
		let entryToTarget = { ...copiedHistory[copiedHistory.length - 1] };

		props.updateChatHistory(userEntry);
		setIsStreaming(true);

		const run = new Promise((resolve, reject) => {
			console.log('Starting stream...');
			openai.beta.threads.runs
				.stream(thread.id, {
					assistant_id: assistantId,
				})
				.on('textCreated', (text) => {
					//console.log('Text created:', text);
					//setOutput((prevOutput) => `${prevOutput}\nassistant > `);
				})
				.on('textDelta', (textDelta, snapshot) => {
					// console.log('Text delta:', textDelta);
					setOutput((prevOutput) => `${prevOutput}${textDelta.value}`);

					entryToTarget.text = entryToTarget.text + textDelta.value;
					//copiedHistory[copiedHistory.length - 1] = entryToTarget;
					props.updateChatHistory(entryToTarget);
				})
				.on('toolCallCreated', (toolCall) => {
					console.log('Tool call created:', toolCall);
					/*setOutput(
						(prevOutput) => `${prevOutput}\nassistant > ${toolCall.type}\n\n`
					);*/
				})
				.on('toolCallDelta', (toolCallDelta, snapshot) => {
					console.log('Tool call delta:', toolCallDelta);
					if (toolCallDelta.type === 'file_search') {
						if (toolCallDelta.file_search.input) {
							setOutput(
								(prevOutput) =>
									`${prevOutput}${toolCallDelta.file_search.input}`
							);
						}
						if (toolCallDelta.file_search.outputs) {
							setOutput((prevOutput) => `${prevOutput}\noutput >\n`);
							toolCallDelta.file_search.outputs.forEach((output) => {
								if (output.type === 'logs') {
									setOutput((prevOutput) => `${prevOutput}\n${output.logs}\n`);
								}
							});
						}
					}
				})
				.on('messageDone', () => {
					setIsStreaming(false);
					// addMessagetoHistory('Assistant', `${output}`); //for now, fix so it streams correctly
				})
				.on('error', (error) => {
					console.log('Error:', error);
					setIsStreaming(false);
				});
		});
	};

	const getWelcomePrompt = () => {
		let currentID = props.item.itemid,
			currentPrompts = prompts.filter((f) => f.screenID === currentID);

		if (currentPrompts.length > 0) {
			let welcomePrompts = currentPrompts[0].scenarios.filter(
				(f) => f.type === 'load'
			);
			console.log(welcomePrompts);
			if (welcomePrompts.length > 0) {
				//do we randomize and pick one?
				let selected =
					welcomePrompts[0].prompts[
						Math.floor(Math.random() * welcomePrompts[0].prompts.length)
					];
				setWelcomePrompt(selected);
				addMessagetoHistory('welcome', selected.question);
			} else {
				setWelcomePrompt(0);
				addMessagetoHistory('welcome', selected.question);
			}
		}
	};

	/* Coach Me ---*/
	const coachMe = () => {
		console.log('hello');
		if (coachMeCount < 3) {
			let currentID = props.item.itemid,
				currentPrompts = prompts.filter((f) => f.screenID === currentID);

			if (currentPrompts.length > 0) {
				let coachMePrompts = currentPrompts[0].scenarios.filter(
					(f) => f.type === 'coachme'
				);

				console.log(coachMePrompts);

				let currentCoachMePrompt = coachMePrompts[coachMeCount];
				addMessagetoHistory('coachme', currentCoachMePrompt.question);
			}

			setCoachMeCount((coachMeCount) => coachMeCount + 1);
		}
	};

	useEffect(() => {
		if (props.pageType === 'assignment') {
			setTimeout(() => getWelcomePrompt(), 500);
		} else if (props.chatHistory.length > 0) {
			let message = '<p>In progress message on overview goes here...</p>';
			addMessagetoHistory('Assistant', message);
		} else {
			/*let message =
				'<p>Hello!</p><p>In this assignment, you\'ll explore the microscopic structure and function of skeletal muscle fibers.</p>';
			addMessagetoHistory('welcome', message); */
		}
	}, [props.item]);

	const addMessagetoHistory = (type, message) => {
		let newMessage;
		let itemID = props.item?.itemid.split('item')[1];

		console.log(props.item);
		if (itemID) {
			newMessage = { user: type, text: message, itemId: itemID };
		} else {
			newMessage = { user: type, text: message };
		}

		props.updateChatHistory(newMessage);
		//eventually dispatch to update state
	};

	const sentChat = (message) => {
		// let message = input.current.value;
		addMessagetoHistory('user', message);

		//if last item in history is the welcome message, AND message is a yes
		let assistantMsgs = props.chatHistory.filter((f) => f.user !== 'user'),
			lastHistoryMsg = assistantMsgs[assistantMsgs.length - 1];

		console.log(lastHistoryMsg);
		console.log(welcomePrompt.question);
		if (welcomePrompt.question === lastHistoryMsg.text) {
			console.log('same as welcome');
			console.log(message);
			//if yes
			if (message.toLowerCase() === 'yes') {
				console.log('i said yes');
				setTimeout(
					() =>
						addMessagetoHistory(
							'assistant',
							welcomePrompt.response.type === 'text'
								? welcomePrompt.response.content
								: 'Insert ' + welcomePrompt.response.type
						),
					1000
				);
			} else if (message.toLowerCase() === 'no') {
				setTimeout(
					() =>
						addMessagetoHistory(
							'assistant',
							'Okay, let me know if you have any questions along the way.'
						),
					1000
				);
			}
			//if anything else initiate chat gpt i think?
		} else {
			console.log('different from welcome');
		}

		//clear input
		input.current.value = '';
	};
	const initiateUserChatAndResponse = () => {
		//if we want to have the prompts as buttons and trigger a user message
		addMessagetoHistory(
			'user',
			welcomePrompt.userResponse
				? welcomePrompt.userResponse
				: welcomePrompt.question
		);
		setTimeout(
			() =>
				addMessagetoHistory(
					'assistant',
					welcomePrompt.response.type === 'text'
						? welcomePrompt.response.content
						: 'Insert ' + welcomePrompt.response.type
				),
			1200
		);
	};

	return (
		<div
			className={`chatbot-drawer
				${enter ? 'slide-in' : ''}
				${
					props.drawerMode === 'overlay'
						? 'overlay'
						: props.drawerMode === 'floating'
						? 'floating'
						: 'sidebyside'
				}`}
		>
			<div className='drawer-header'>
				<h2>{props.drawerType === 'search' ? 'Search' : 'Learning coach'}</h2>
				<div className='drawer-header-actions'>
					<DrawerViewMenu
						drawerMode={props.drawerMode}
						changeDrawerMode={props.changeDrawerMode}
					/>

					<button
						className='close-btn'
						onClick={props.close}
					>
						<img
							src={iconClose}
							alt=''
						/>
					</button>
				</div>
			</div>
			<div className={`drawer-body ${props.drawerType}`}>
				{props.drawerType === 'search' ? (
					<SearchContent />
				) : props.pageType === 'overview' ? (
					<WelcomeMessage />
				) : (
					<>
						<div className='response'>
							{props.chatHistory?.length > 0 &&
								props.chatHistory.map((item, index) => {
									//clean this part up
									if (
										welcomePrompt &&
										item.text === welcomePrompt.question &&
										welcomePrompt.buttons
									) {
										return (
											<div
												className={`message from-${item.user.toLowerCase()}`}
												key={'message' + index}
											>
												{item.itemId && (
													<div className='item-id'>
														<a href='!#'>{item.itemId}</a>
													</div>
												)}
												<div dangerouslySetInnerHTML={{ __html: item.text }} />
												<div className='from-welcome-actions'>
													<button
														className='chat-btn'
														onClick={() => sentChat('Yes')}
													>
														Yes
													</button>
													<button
														className='chat-btn'
														onClick={() => sentChat('No')}
													>
														No
													</button>
												</div>
											</div>
										);
									} else if (
										welcomePrompt &&
										item.text === welcomePrompt.question &&
										welcomePrompt.userResponse
									) {
										return (
											<button
												className={`message from-${item.user.toLowerCase()}`}
												key={'message' + index}
												dangerouslySetInnerHTML={{ __html: item.text }}
												onClick={() => initiateUserChatAndResponse()}
											></button>
											//
										);
									} else {
										return (
											<div
												className={`message from-${item.user.toLowerCase()}`}
												key={'message' + index}
												dangerouslySetInnerHTML={{ __html: item.text }}
											></div>
										);
									}
								})}
							<div ref={messagesEndRef}></div>
							{/* <p>You are viewing an {props.pageType} page.</p>
						{props.item && <p>You are on {props.item.name}.</p>} */}
							{/* {welcomePrompt ? <div className='message from-bot' dangerouslySetInnerHTML={{__html: welcomePrompt.question}} /> : <div className='message from-bot'><p>Welcome!</p><p>I'm here to help with anything related to this assignment.</p></div> */}

							{/* <pre className='gpt'>{output}</pre> */}
						</div>

						<div className='chat'>
							<div
								className='gpt-actions'
								onClick={coachMe}
								disabled={coachMeCount === 2}
							>
								<button className='chat-btn'>Coach me</button>
							</div>
							<form
								className='input-container'
								onSubmit={(e) => submitChatInput(e)}
							>
								<input
									type='text'
									placeholder='Ask me anything...'
									ref={input}
									defaultValue={textInput}
									onKeyUp={(e) => setTextInput(e.target.value)}
								/>
								<div className='chat-actions'>
									<button aria-label='microphone'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
										>
											<path d='M18.25 10.9961C18.6297 10.9961 18.9435 11.2782 18.9932 11.6443L19 11.7461V12.2461C19 15.8055 16.245 18.7214 12.751 18.9778L12.75 21.2461C12.75 21.6603 12.4142 21.9961 12 21.9961C11.6203 21.9961 11.3065 21.7139 11.2568 21.3479L11.25 21.2461L11.25 18.9779C7.83323 18.7277 5.12283 15.9341 5.00406 12.4824L5 12.2461V11.7461C5 11.3319 5.33579 10.9961 5.75 10.9961C6.1297 10.9961 6.44349 11.2782 6.49315 11.6443L6.5 11.7461V12.2461C6.5 15.0731 8.73445 17.3782 11.5336 17.4917L11.75 17.4961H12.25C15.077 17.4961 17.3821 15.2616 17.4956 12.4625L17.5 12.2461V11.7461C17.5 11.3319 17.8358 10.9961 18.25 10.9961ZM12 1.99609C14.2091 1.99609 16 3.78695 16 5.99609V11.9961C16 14.2052 14.2091 15.9961 12 15.9961C9.79086 15.9961 8 14.2052 8 11.9961V5.99609C8 3.78695 9.79086 1.99609 12 1.99609ZM12 3.49609C10.6193 3.49609 9.5 4.61538 9.5 5.99609V11.9961C9.5 13.3768 10.6193 14.4961 12 14.4961C13.3807 14.4961 14.5 13.3768 14.5 11.9961V5.99609C14.5 4.61538 13.3807 3.49609 12 3.49609Z' />
										</svg>
									</button>
									<button
										type='submit'
										aria-label='Send chat'
										disabled={textInput.length === 0}
									>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
										>
											<path d='M12.8147 12.1933L5.28344 13.4485C5.10705 13.4779 4.95979 13.5992 4.89723 13.7668L2.29933 20.7242C2.05066 21.3636 2.72008 21.9737 3.33375 21.6668L21.3337 12.6668C21.8865 12.3904 21.8865 11.6016 21.3337 11.3252L3.33375 2.32518C2.72008 2.01835 2.05066 2.62839 2.29933 3.26784L4.89723 10.2252C4.95979 10.3928 5.10705 10.5141 5.28344 10.5435L12.8147 11.7987C12.9236 11.8169 12.9972 11.9199 12.9791 12.0289C12.965 12.1132 12.899 12.1792 12.8147 12.1933Z' />
										</svg>
									</button>
								</div>
							</form>
						</div>
					</>
				)}
			</div>
			{props.drawerType === 'chat' && (
				<div className='drawer-footer'>
					Don't share personal info. Check for mistakes. <br />
					See our <a href='#!'>FAQs</a>.
				</div>
			)}
		</div>
	);
};

export default ChatbotDrawer;
