/** @format */
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import bookImg from '../assets/images/book_img.png';
import mediaImg1 from '../assets/images/media1.png';
import mediaImg2 from '../assets/images/media2.png';
import mediaImg3 from '../assets/images/media3.png';
import videoImg1 from '../assets/images/video1.png';
import videoImg2 from '../assets/images/video2.png';

const SearchResults = () => {
	return (
		<div className='search-results'>
			<div className='search-glossary'>
				<header>
					<h3>Glossary</h3>
					<div>
						{/*<BookmarkBorderIcon fontSize='small' />*/}
						<MoreHorizIcon fontSize='small' />
					</div>
				</header>

				<p>
					Muscle composed of cylindrical multinucleate cells with obvious
					stations; the muscle(s) attached to the body's skeleton; voluntary
					muscle.
				</p>
			</div>

			<div className='search-etextbook'>
				<header>
					<h3>Etextbook</h3>
					<a href='!#'>Show all (10)</a>
				</header>
				<div className='content'>
					<img
						src={bookImg}
						alt=''
					/>
					<ul>
						<li>
							<strong>4.4</strong> Muscle tissue is responsible for body
							movement <ArrowRightAltIcon fontSize='small' />
						</li>
						<li>
							<strong>6.2</strong> Bones function several important functions{' '}
							<ArrowRightAltIcon fontSize='small' />
						</li>
						<li>
							<strong>9.2</strong> Skeletal muscle is made up of muscle fibers,
							nerves, blood vessels <ArrowRightAltIcon fontSize='small' />
						</li>
					</ul>
				</div>
			</div>

			<div className='search-media'>
				<header>
					<h3>Images</h3>
					<a href='!#'>Show all (14)</a>
				</header>
				<div className='content'>
					<ul className='media'>
						<li>
							<img
								src={mediaImg1}
								alt=''
							/>
							<p>
								<strong>Table 9.1</strong> Structure and organizational levels
							</p>
						</li>
						<li>
							<img
								src={mediaImg2}
								alt=''
							/>
							<p>
								<strong>Figure 4.12a</strong> Skletal muscle
							</p>
						</li>
						<li>
							<img
								src={mediaImg3}
								alt=''
							/>
							<p>
								<strong>Figure 9.1</strong> Connective sheaths
							</p>
						</li>
					</ul>
				</div>
			</div>
			<div className='search-media'>
				<header>
					<h3>Videos</h3>
					<a href='!#'>Show all (7)</a>
				</header>
				<div className='content'>
					<ul className='media'>
						<li className='card'>
							<img
								src={videoImg1}
								alt=''
							/>
							<p>
								<strong>Career connection:</strong>
								Christian - R.N. Neuro Medical-Surgical
							</p>
						</li>
						<li className='card'>
							<img
								src={videoImg2}
								alt=''
							/>
							<p>
								<strong>Video tutor:</strong> Events at the Neuromuscular
								Junction
							</p>
						</li>
					</ul>
				</div>
			</div>

			<div className='search-tools'>
				<header>
					<h3>Study Tools</h3>
					<a href='#!'>Show all</a>
				</header>
				<ul>
					<li className='card'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
						>
							<path
								d='M12.1827 3.29292C11.5159 3.11425 10.83 3.50985 10.6508 4.17651L7.10608 17.3624C6.92687 18.0291 7.32214 18.7144 7.98895 18.893L14.7591 20.7071C15.4259 20.8858 16.1118 20.4902 16.291 19.8235L19.8357 6.63759C20.0149 5.97093 19.6196 5.28565 18.9528 5.10698L12.1827 3.29292ZM9.44345 3.853C9.80188 2.51967 11.1736 1.72848 12.5072 2.08582L19.2773 3.89988C20.611 4.25722 21.4015 5.62778 21.0431 6.9611L17.4983 20.147C17.1399 21.4803 15.7682 22.2715 14.4346 21.9142L7.66444 20.1001C6.33082 19.7428 5.54028 18.3722 5.89872 17.0389L9.44345 3.853ZM14.8726 8.25008C15.5629 8.25008 16.1225 7.69046 16.1225 7.00013C16.1225 6.30979 15.5629 5.75017 14.8726 5.75017C14.1822 5.75017 13.6226 6.30979 13.6226 7.00013C13.6226 7.69046 14.1822 8.25008 14.8726 8.25008ZM13.3101 16.9998C13.3101 17.6901 12.7505 18.2497 12.0602 18.2497C11.3698 18.2497 10.8102 17.6901 10.8102 16.9998C10.8102 16.3094 11.3698 15.7498 12.0602 15.7498C12.7505 15.7498 13.3101 16.3094 13.3101 16.9998ZM8.81771 2H8.62762C8.27626 2 7.94176 2.07249 7.63828 2.20333C7.50062 2.26268 7.36935 2.33404 7.24577 2.41608C6.57159 2.86369 6.12672 3.6294 6.12566 4.49889L6.12001 11.4024L7.37378 6.73852L7.37562 4.50094C7.37609 3.92358 7.76794 3.43662 8.30002 3.29304L8.39882 2.92554C8.45562 2.71424 8.53278 2.51383 8.62762 2.32586C8.68476 2.21261 8.74832 2.10388 8.81771 2ZM4.92211 3.31831C4.88958 3.49869 4.87253 3.68443 4.87237 3.87412L4.87172 4.67093C4.39588 4.94766 4.14383 5.51785 4.29266 6.07508L4.8688 8.23236L4.86485 13.0618L3.08529 6.39859C2.72904 5.06468 3.52135 3.69365 4.85497 3.3363L4.92211 3.31831Z'
								fill='#666666'
							/>
						</svg>

						<p>
							<strong>Closer Connections</strong>
							The Muscular System
						</p>
					</li>
					<li className='card'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
						>
							<path
								d='M22 12V19C22 20.5977 20.7511 21.9037 19.1763 21.9949L19 22H16C15.4872 22 15.0645 21.614 15.0067 21.1166L15 21V15C15 14.4872 15.386 14.0645 15.8834 14.0067L16 14H20.5V12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12V14H8C8.55228 14 9 14.4477 9 15V21C9 21.5523 8.55228 22 8 22H5C3.34315 22 2 20.6569 2 19V12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12V19V12ZM7.5 15.5H3.5V19C3.5 19.8284 4.17157 20.5 5 20.5H7.5V15.5ZM20.5 15.5H16.5V20.5H19C19.7797 20.5 20.4204 19.9051 20.4931 19.1445L20.5 19V15.5Z'
								fill='#666666'
							/>
						</svg>

						<p>
							<strong>MP3 Tutors</strong>
							Sliding Filament Theory of Contraction
						</p>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default SearchResults;
