import React, { forwardRef } from "react";
import QuestionStem from './QuestionStem';
import QuestionPart from "./QuestionPart";
// import questions from '../assets/data/tdxquestions.json';

const TDXBody = forwardRef((props, ref) => {
    
    
    const currentQuestionParts = props.data.content ? props.data.content : [];
    console.log(props.data)


    return (
        <main className="tdx-body">            
            
            <QuestionStem
                title={props.data.name}
                data={props.data.questionBody ? props.data.questionBody : null}             
                openPanel={props.openPanel}
                closePanel={props.closePanel}
                panelOpen={props.panelOpen}
                totalParts={props.data.content ? props.data.content.length : 1}
                totalPoints={props.data.totalPoints}
                pointsEarned={props.data.pointsEarned}
            />
            <div className="question-parts">
                {props.data?.content.map((part, i) => {
                    return(
                        i <= props.currentPart ?
                        <QuestionPart 
                            data={part} 
                            currentPart={i} 
                            totalParts={currentQuestionParts.length}
                            selectedAnswers={props.selectedAnswers}
                            selectAnswer={props.selectAnswer}
                            graded={props.done || props.currentPart > i }
                            key={`Qpart${i}-${props.random}`}
                            ref={ref}
                            random={props.data.itemid}
                        /> : null
                    )
                })}
            </div>
        </main>
    )    
})

export default TDXBody;